import React, { useContext, useEffect } from "react";
import Sidebar from "./Sidebar";
import { VoivodeshipContext } from "../data/context/voivodeship.context";
import Select from "./Select";
import EmployeesList from "./EmployeesList";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();
  const voivodeshipStore = useContext(VoivodeshipContext);

  const {
    voivodeships,
    setActiveVoivodeship,
    activeVoivodeship,
  } = voivodeshipStore;

  const voivodeshipsArray = Object.entries(voivodeships).map((pipe) => ({
    value: pipe[0],
    label: pipe[1],
  }));

  useEffect(() => {
    return () => {
      setActiveVoivodeship("");
    };
  }, []);

  return (
    <>
      <Sidebar>
        <p className="FilterModule__subtitle">{t("Wybierz województwo")}</p>
        <Select
          active={activeVoivodeship}
          callback={setActiveVoivodeship}
          options={voivodeshipsArray}
        />
      </Sidebar>
      <EmployeesList active={activeVoivodeship} />
    </>
  );
};
