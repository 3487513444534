import React, { useState, useEffect, createContext, useContext } from "react";
import {
	getEmployeesByCountry,
	getEmployeesByDepartment,
	getEmployeesByVoivodeship,
} from "../fetch/employee.fetch";
import { DepartmentContext } from "./department.context";
import { VoivodeshipContext } from "./voivodeship.context";
import { CountriesContext } from "./countries.context";

export const EmployeeContext = createContext();

export const EmployeeProvider = ({ children }) => {
	const [employees, setEmployees] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const departmentStore = useContext(DepartmentContext);
	const voivodeshipStore = useContext(VoivodeshipContext);
	const countriesStore = useContext(CountriesContext);

	const fetchEmployeesByDepartment = async () => {
		if (departmentStore.activeDepartment) {
			setIsLoading(true);
			const data = await getEmployeesByDepartment(
				departmentStore.activeDepartment
			);
			setEmployees(data);
			setIsLoading(false);
		}
	};

	const fetchEmployeesByVoivodeship = async () => {
		if (voivodeshipStore.activeVoivodeship) {
			setIsLoading(true);
			const data = await getEmployeesByVoivodeship(
				voivodeshipStore.activeVoivodeship
			);
			setEmployees(data);
			setIsLoading(false);
		}
	};

	const fetchEmployeesByCountry = async () => {
		if (countriesStore.activeCountry) {
			setIsLoading(true);
			const data = await getEmployeesByCountry(countriesStore.activeCountry);
			setEmployees(data);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchEmployeesByDepartment();
	}, [departmentStore.activeDepartment]);

	useEffect(() => {
		fetchEmployeesByCountry();
	}, [countriesStore.activeCountry]);

	useEffect(() => {
		fetchEmployeesByVoivodeship();
	}, [voivodeshipStore.activeVoivodeship]);

	return (
		<EmployeeContext.Provider
			value={{
				fetchEmployeesByDepartment,
				isLoading,
				setIsLoading,
				employees,
				setEmployees,
			}}
		>
			{children}
		</EmployeeContext.Provider>
	);
};
