import React from "react";
import { render } from "react-dom";
import { DepartmentProvider } from "./src/data/context/department.context";
import { EmployeeProvider } from "./src/data/context/employee.context";
import { CountriesProvider } from "./src/data/context/countries.context";
import { VoivodeshipProvider } from "./src/data/context/voivodeship.context";
import { DistributorsProvider } from "./src/data/context/distributors.context";
import FilterModule from "./FilterModule";
import "./i18n";

const App = () => {
	return (
		<DepartmentProvider>
			<VoivodeshipProvider>
				<CountriesProvider>
					<EmployeeProvider>
						<DistributorsProvider>
							<FilterModule />
						</DistributorsProvider>
					</EmployeeProvider>
				</CountriesProvider>
			</VoivodeshipProvider>
		</DepartmentProvider>
	);
};

const root = document.getElementById("AlvoContact");

if (root) {
	render(<App />, root);
}
