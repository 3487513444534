import React from "react";

const Distributor = ({ distributor }) => {
	return (
		<li className="Card DistributorCard EmployeeCard">
			<div className="Card__content">
				<h3 className="Card__title DistributorCard__title">
					{distributor.title.rendered}
				</h3>
				<div
					className="Card__editorContent Content"
					dangerouslySetInnerHTML={{ __html: distributor.content.rendered }}
				></div>
			</div>
		</li>
	);
};

export default Distributor;
