import React from "react";
import Tabs from "./src/components/Tabs";
import Tab from "./src/components/Tab";
import DepartmentsTab from "./src/components/DepartmentsTab";
import VoivodeshipTab from "./src/components/VoivodeshipTab";
import CountriesTab from "./src/components/CountriesTab";
import { useTranslation } from "react-i18next";
const FilterModule = () => {
	const { t } = useTranslation();

	return (
		<section className="Section FilterModule">
			<div className="Container">
				<Tabs>
					<Tab name={t("Działy ALVO")} index="1">
						<DepartmentsTab />
					</Tab>
					<Tab name={t("Zespół doradców handlowych")} index="2">
						<VoivodeshipTab />
					</Tab>
					<Tab name={t("Dystrybutorzy na świecie")} index="3">
						<CountriesTab />
					</Tab>
				</Tabs>
			</div>
		</section>
	);
};

export default FilterModule;
