import React, { useState, useEffect, createContext } from "react";
import { getVoivodeships } from "../fetch/voivodeship.fetch";

export const VoivodeshipContext = createContext();

export const VoivodeshipProvider = ({ children }) => {
	const [voivodeships, setVoivodeships] = useState([]);
	const [activeVoivodeship, setActiveVoivodeship] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	const fetchVoivodeships = async () => {
		const data = await getVoivodeships();
		setVoivodeships(data);
		setIsLoading(false);
	};

	useEffect(() => {
		fetchVoivodeships();
	}, []);

	return (
		<VoivodeshipContext.Provider
			value={{
				isLoading,
				setIsLoading,
				voivodeships,
				fetchVoivodeships,
				activeVoivodeship,
				setActiveVoivodeship,
			}}
		>
			{children}
		</VoivodeshipContext.Provider>
	);
};
