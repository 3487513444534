import React, { useContext, useEffect } from "react";
import { DistributorsContext } from "../data/context/distributors.context";
import Loader from "./Loader";
import Distributor from "./Distributor";
import { useTranslation } from "react-i18next";

const DistributorsList = React.forwardRef((props, ref) => {
	const { t } = useTranslation();
	const distributorsStore = useContext(DistributorsContext);

	const { isLoading, distributors, setDistributors } = distributorsStore;

	useEffect(() => {
		return () => {
			setDistributors(null);
		};
	}, []);

	if (isLoading) {
		return <Loader />;
	}

	if (distributors === null) {
		return (
			<p className="Text">
				{t(
					"Wyszukaj dystrybutorów za pomocą podanych filtrów po lewej stronie."
				)}
			</p>
		);
	}

	if (!distributors.length) {
		return (
			<p className="Text"> {t("Nie znalezionych żadnych dystrybutorów.")}</p>
		);
	}

	return (
		<ul
			ref={ref}
			className={`FilterModule__employeesList ${
				props.direction === "vertical"
					? "FilterModule__employeesList--vertical"
					: ""
			}`}
		>
			{distributors.map((distributor) => (
				<Distributor key={distributor.id} distributor={distributor} />
			))}
		</ul>
	);
});

export default DistributorsList;
