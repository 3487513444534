import { SITE_URL } from "../constants/fetch.constants";

export const getCountries = async (offset = 0) => {
	const request = await fetch(
		`${SITE_URL}/wp-json/wp/v2/countries?lang=${document
			.getElementById("AlvoContact")
			.getAttribute("data-lang")}&per_page=100&offset=${offset}`
	);
	const response = await request.json();

	return response;
};
