import React, { useContext, useRef, useEffect } from "react";
import Loader from "./Loader";
import DepartmentList from "./DepartmentList";
import DepartmentItem from "./DepartmentItem";
import EmployeesList from "./EmployeesList";
import Sidebar from "./Sidebar";
import { handleScrollIntoView } from "../helpers/scroll";
import { isMobile } from "react-device-detect";
import { DepartmentContext } from "../data/context/department.context";
import { EmployeeContext } from "../data/context/employee.context";

export default () => {
	const departmentsStore = useContext(DepartmentContext);
	const employeeStore = useContext(EmployeeContext);

	const { setEmployees, fetchEmployeesByDepartment } = employeeStore;

	const {
		departments,
		isLoading,
		setActiveDepartment,
		activeDepartment,
		fetchDepartments,
	} = departmentsStore;

	const ref = useRef(null);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			if (isMobile) {
				handleScrollIntoView(ref);
			}
		}
		return () => (mounted = false);
	}, [activeDepartment]);

	useEffect(() => {
		return () => {
			setActiveDepartment(null);
			setEmployees(null);
		};
	}, []);

	return (
		<>
			<Sidebar>
				{!isLoading ? (
					<DepartmentList>
						{departments.map((department) => (
							<DepartmentItem key={department.id} item={department} />
						))}
					</DepartmentList>
				) : (
					<Loader />
				)}
			</Sidebar>
			<EmployeesList ref={ref} />
		</>
	);
};
