import React, { useState, useEffect, createContext, useContext } from "react";
import { getDistributorsByCountry } from "../fetch/distributors.fetch";
import { CountriesContext } from "./countries.context";

export const DistributorsContext = createContext();

export const DistributorsProvider = ({ children }) => {
	const [distributors, setDistributors] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const countriesStore = useContext(CountriesContext);

	const fetchDistributorsByCountry = async () => {
		if (countriesStore.activeCountry) {
			setIsLoading(true);
			const data = await getDistributorsByCountry(countriesStore.activeCountry);
			setDistributors(data);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchDistributorsByCountry();
	}, [countriesStore.activeCountry]);

	return (
		<DistributorsContext.Provider
			value={{
				fetchDistributorsByCountry,
				isLoading,
				setIsLoading,
				distributors,
				setDistributors,
			}}
		>
			{children}
		</DistributorsContext.Provider>
	);
};
