import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./translation";

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: translationEN,
		},
	},
	lng: document.getElementById("AlvoContact")
		? document.getElementById("AlvoContact").getAttribute("data-lang")
		: "en",
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
