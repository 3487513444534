import React, { useState, useEffect, createContext } from "react";
import { getDepartments } from "../fetch/department.fetch";

export const DepartmentContext = createContext();

export const DepartmentProvider = ({ children }) => {
	const [departments, setDepartments] = useState([]);
	const [activeDepartment, setActiveDepartment] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const fetchDepartments = async () => {
		const data = await getDepartments();
		setDepartments(data);
		setActiveDepartment(data[0].id);
		setIsLoading(false);
	};

	useEffect(() => {
		fetchDepartments();
	}, []);

	return (
		<DepartmentContext.Provider
			value={{
				isLoading,
				setIsLoading,
				departments,
				fetchDepartments,
				activeDepartment,
				setActiveDepartment,
			}}
		>
			{children}
		</DepartmentContext.Provider>
	);
};
