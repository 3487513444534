import { SITE_URL } from "../constants/fetch.constants";
const LANG = document.getElementById("AlvoContact").getAttribute("data-lang");

export const getDistributorsByCountry = async (country) => {
	const request = await fetch(
		`${SITE_URL}/wp-json/wp/v2/distributor?country=${country}&lang=${LANG}`
	);

	const response = await request.json();

	return response;
};
