import { SITE_URL } from "../constants/fetch.constants";

export const getDepartments = async () => {
	const request = await fetch(
		`${SITE_URL}/wp-json/wp/v2/department?lang=${document
			.getElementById("AlvoContact")
			.getAttribute("data-lang")}&per_page=100`
	);
	const response = await request.json();

	return response;
};
export const getDepartmentById = async (id = 0) => {
	const request = await fetch(`${SITE_URL}/wp-json/wp/v2/department/${id}`);
	const response = await request.json();

	return response;
};
