import React, { useContext, useEffect } from "react";
import { EmployeeContext } from "../data/context/employee.context";
import Loader from "./Loader";
import Employee from "./Employee";
import { useTranslation } from "react-i18next";

const EmployeesList = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const employeeStore = useContext(EmployeeContext);

  const { isLoading, employees, setEmployees } = employeeStore;

  useEffect(() => {
    return () => {
      setEmployees(null);
    };
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (employees === null) {
    return (
      <p className="Text">
        {t("Wyszukaj pracowników za pomocą podanych filtrów po lewej stronie.")}
      </p>
    );
  }

  if (!employees.length) {
    return (
      <p className="Text"> {t("Nie znalezionych żadnych pracowników.")}</p>
    );
  }

  return (
    <ul
      ref={ref}
      className={`FilterModule__employeesList ${
        props.direction === "vertical"
          ? "FilterModule__employeesList--vertical"
          : ""
      }`}
    >
      {employees.map((employee) => (
        <>
          {props.active === undefined ? (
            <Employee key={employee.id} employee={employee} />
          ) : (
            <>
              {employee.voivodeship !== null && (
                <>
                  {employee.voivodeship.some(
                    (item) => item.value === props.active
                  ) ? (
                    <Employee key={employee.id} employee={employee} />
                  ) : null}
                </>
              )}
            </>
          )}
        </>
      ))}
    </ul>
  );
});

export default EmployeesList;
