import React from "react";
import { useTranslation } from "react-i18next";

export default ({ options, callback, active }) => {
	const { t } = useTranslation();

	const handleChange = (ev) => {
		callback(ev.target.value);
	};

	return (
		<select onChange={handleChange} value={active} className="Select">
			<option value="">{t("Wybierz opcje")}</option>
			{options?.map(({ label, value }) => (
				<option
					key={value}
					value={value}
					dangerouslySetInnerHTML={{ __html: label }}
				></option>
			))}
		</select>
	);
};
