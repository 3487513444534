import React, { useContext, useEffect } from "react";
import Sidebar from "./Sidebar";
import EmployeesList from "./EmployeesList";
import DistributorsList from "./DistributorsList";
import Select from "./Select";
import { CountriesContext } from "../data/context/countries.context";
import { useTranslation } from "react-i18next";

export default (props) => {
	const { t } = useTranslation();
	const countriesStore = useContext(CountriesContext);

	const { countries, activeCountry, setActiveCountry } = countriesStore;

	const countriesArray = countries
		.map((country) => ({
			value: country.id,
			label: country.name,
		}))
		.sort((a, b) => a.label.localeCompare(b.label));

	useEffect(() => {
		return () => {
			setActiveCountry("");
		};
	}, []);

	return (
		<>
			<Sidebar>
				<p className="FilterModule__subtitle">{t("Wybierz kraj")}</p>
				<Select
					callback={setActiveCountry}
					options={countriesArray}
					active={activeCountry}
				/>
			</Sidebar>
			<div className="CountriesRow">
				{/* <div className="CountriesRow__column">
					<p className="FilterModule__subtitle">{t("Dystrybutorzy")}</p>
					<DistributorsList direction="vertical" />
				</div> */}
				<div className="CountriesRow__column">
					<p className="FilterModule__subtitle">{t("Area Manager")}</p>
					<EmployeesList direction="vertical" />
				</div>
			</div>
		</>
	);
};
